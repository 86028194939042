<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div class="page-header">
      <h1>
        <span>{{ $tc("user", 2) }} &ndash;</span>
        {{ $t("create") }}
      </h1>
    </div>

    <div class="card">
      <div class="card-content pt-3">
        <BackButton :parent-route="{ name: 'UserIndex' }"></BackButton>

        <form class="mt-5" @submit.prevent="submit">
          <CustomInput
            v-model="form.emailAddress"
            :disabled="createNewUser || userAlreadyExists"
            :label="$t('email')"
            :type="'email'"
          ></CustomInput>

          <b-field :label="$t('role_company')" label-for="role1" horizontal>
            <b-select id="role1" v-model="companyUserRole" required>
              <option
                v-for="r in companyUserRoles"
                :key="r.role"
                :value="r.role"
                :disabled="r.disabled"
              >
                {{ $t(r.role) }}
                {{ r.disabled ? ` (${$t("limit_reached")})` : "" }}
              </option>
            </b-select>
          </b-field>

          <b-button
            v-if="step === 1"
            type="is-primary"
            native-type="submit"
            class="mt-5"
            :loading="loading"
          >
            {{ $t("next") }}
          </b-button>

          <b-message v-if="userAlreadyExists" type="is-primary">
            {{ $t("account_already_exists") }}
          </b-message>

          <CustomInput
            v-if="createNewUser || userAlreadyExists"
            v-model="form.name"
            :label="$t('name')"
            :disabled="userAlreadyExists"
          ></CustomInput>

          <CustomInput
            v-if="createNewUser || userAlreadyExists"
            v-model="form.phoneNumber"
            :label="$t('phone')"
            :type="'phone'"
            :disabled="userAlreadyExists"
          ></CustomInput>

          <b-field
            v-if="createNewUser || userAlreadyExists"
            :label="$tc('language', 1)"
            label-for="language"
            horizontal
          >
            <b-select
              id="language"
              v-model="form.language"
              :disabled="userAlreadyExists"
              required
            >
              <option v-for="l in languages" :key="l.id" :value="l['@id']">
                {{ l.isoName }}
              </option>
            </b-select>
          </b-field>

          <b-button
            v-if="step === 2"
            type="is-primary"
            native-type="submit"
            class="mt-5"
            icon-left="content-save"
            :loading="loading"
          >
            {{ $t("save") }}
          </b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import BackButton from "../../components/BackButton";
import CustomInput from "../../components/forms/CustomInput.vue";

export default {
  name: "UserCreateForCompanyAdmin",
  components: { BackButton, CustomInput },
  data() {
    return {
      loading: false,
      step: 1,
      createNewUser: false,
      userAlreadyExists: false,
      form: {
        name: null,
        emailAddress: null,
        phoneNumber: null,
        language: null,
      },
      companyUserRoles: [
        { role: "ROLE_COMPANY_USER" },
        { role: "ROLE_COMPANY_ADMIN", disabled: true },
      ],
      companyUserRole: "ROLE_COMPANY_USER",
    };
  },
  computed: {
    ...mapGetters(["findLanguageByISOCode", "userHasRoleSuperAdmin"]),
    ...mapState({
      entity: (state) => state.auth.entity,
      languages: (state) => state.global.languages,
    }),
  },
  async mounted() {
    this.form.language = this.findLanguageByISOCode("en")["@id"];

    const companyUsers = await this.getAllCompanyUsers(this.entity.id);

    // Only allow company admin role when limit (2) not reached
    const admins = companyUsers.filter(
      (cu) => !cu.blocked && cu.roles.includes("ROLE_COMPANY_ADMIN")
    ).length;
    if (admins < 2) {
      this.companyUserRoles.find(
        (r) => r.role === "ROLE_COMPANY_ADMIN"
      ).disabled = false;
    }
  },
  methods: {
    ...mapActions([
      "checkIfUserExists",
      "createCompanyUser",
      "createUser",
      "getAllCompanyUsers",
      "getUser",
      "resetPasswordRequest",
      "updateCompanyUser",
    ]),
    async submit() {
      this.loading = true;

      if (this.step === 1) {
        this.checkIfUserExists(this.form.emailAddress).then((user) => {
          if (user) {
            this.userAlreadyExists = true;
            this.form.name = user.name;
            this.form.phoneNumber = user.phoneNumber;
            this.form.language = user.language["@id"];
          } else {
            this.createNewUser = true;
          }
          this.step = 2;
          this.loading = false;
        });
      }

      if (this.step === 2) {
        let user;

        if (this.createNewUser) {
          const createdUser = await this.createUser(this.form).catch(() => {
            this.loading = false;
          });
          user = await this.getUser(createdUser.id);

          // User is created with ROLE_COMPANY_USER by default, only update when required
          if (this.companyUserRole !== "ROLE_COMPANY_USER") {
            const companyUser = user.companies.find(
              (cu) => cu.company === this.entity["@id"]
            );
            await this.updateCompanyUser({
              id: companyUser.id,
              companyUser: {
                roles: [this.companyUserRole],
              },
            });
          }

          await this.resetPasswordRequest({
            emailAddress: user.emailAddress,
            isActivation: true,
          });
        } else {
          const existingUser = await this.checkIfUserExists(
            this.form.emailAddress
          );
          user = await this.getUser(existingUser.id);

          await this.createCompanyUser({
            company: this.entity["@id"],
            userAccount: user["@id"],
            roles: [this.companyUserRole],
          });
        }

        this.$buefy.toast.open({
          message: this.$t("user_created"),
          type: "is-primary",
        });

        this.$router.push({ name: "UserView", params: { id: user.id } });
      }
    },
  },
};
</script>
